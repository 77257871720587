import 'jquery';
import 'popper.js'; 

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/css/style.css';
import './assets/css/main.css';
import 'bootstrap';

import 'font-awesome/css/font-awesome.min.css';


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { createApp } from 'vue'



import App from './App.vue'
import i18n from './utils/i18n';
import router from './router'
import { Global } from '@/utils/Global';
import http from '@/utils/http';

let app = createApp(App)
  
app.config.globalProperties.$global = Global;
app.config.globalProperties.$http = http;

// Instala vue-i18n en la aplicación
app.use(i18n);

app.use(router);
app.use(VueSweetalert2);

app.mount('#app')
