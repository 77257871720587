<template>

<banner :language="idioma"></banner>
<windows></windows>
<pasos :language="idioma"></pasos>
<entity></entity>
 


</template>

<script>
import Banner from '../view/Banner'  
import Windows from '../view/Windows'  
import Pasos from '../view/Pasos' 
import Entity from '../view/Entity'    
export default {
  name: 'Home-Page',
  components: {
    Banner,
    Windows,
    Entity,
    Pasos
  },
  data(){
    return {

    }
  },
  created(){
    
  },
  props: {
    msg: String,
    idioma: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
