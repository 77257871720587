export var Global = {
    token: null,
    tokenRecaptcha:null,
    geolocalitation:[],
    provincia: [
        {"id": 1, "text": "PINAR DEL RIO"},
        {"id": 2, "text": "ARTEMISA"},
        {"id": 3, "text": "LA HABANA"},
        {"id": 4, "text": "MAYABEQUE"},
        {"id": 5, "text": "MATANZAS"},
        {"id": 6, "text": "VILLA CLARA"},
        {"id": 7, "text": "CIENFUEGOS"},
        {"id": 8, "text": "SANCTI SPIRITUS"},
        {"id": 9, "text": "CIEGO DE AVILA"},
        {"id": 10, "text": "CAMAGUEY"},
        {"id": 11, "text": "LAS TUNAS"},
        {"id": 12, "text": "HOLGUIN"},
        {"id": 13, "text": "GRANMA"},
        {"id": 14, "text": "SANTIAGO DE CUBA"},
        {"id": 15, "text": "GUANTANAMO"}
      ],
    municipio:[
        {"id": 49, "provincia_id": 3, "text": "ARROYO NARANJO"},
        {"id": 2, "provincia_id": 3, "text": "BOYEROS"},
        {"id": 3, "provincia_id": 3, "text": "CENTRO HABANA"},
        {"id": 7, "provincia_id": 3, "text": "CERRO"},
        {"id": 48, "provincia_id": 3, "text": "COTORRO"},
        {"id": 43, "provincia_id": 3, "text": "DIEZ DE OCTUBRE"},
        {"id": 42, "provincia_id": 3, "text": "GUANABACOA"},
        {"id": 44, "provincia_id": 3, "text": "LA HABANA DEL ESTE"},
        {"id": 5, "provincia_id": 3, "text": "LA HABANA VIEJA"},
        {"id": 47, "provincia_id": 3, "text": "LA LISA"},
        {"id": 46, "provincia_id": 3, "text": "MARIANAO"},
        {"id": 1, "provincia_id": 3, "text": "PLAYA"},
        {"id": 4, "provincia_id": 3, "text": "PLAZA DE LA REVOLUCION"},
        {"id": 41, "provincia_id": 3, "text": "REGLA"},
        {"id": 45, "provincia_id": 3, "text": "SAN MIGUEL DEL PADRON"},
        {"id": 52, "provincia_id": 4, "text": "San Nicolas"},
        {"id": 53, "provincia_id": 10, "text": "FLORIDA"},
        {"id": 54, "provincia_id": 14, "text": "Palma Soriano"},
        {"id": 55, "provincia_id": 2, "text": "San Antonio de los Baños"},
        {"id": 56, "provincia_id": 10, "text": "Nuevitas"},
        {"id": 57, "provincia_id": 2, "text": "Caimito"},
        {"id": 58, "provincia_id": 13, "text": "Manzanillo"},
        {"id": 59, "provincia_id": 15, "text": "El Salvador"},
        {"id": 50, "provincia_id": 2, "text": "Bauta"},
        {"id": 60, "provincia_id": 15, "text": "Guisa"},
        {"id": 61, "provincia_id": 4, "text": "Melena del Sur"},
        {"id": 62, "provincia_id": 4, "text": "Batabano"},
        {"id": 63, "provincia_id": 6, "text": "Santa Clara"},
        {"id": 64, "provincia_id": 14, "text": "Santiago de Cuba"},
        {"id": 65, "provincia_id": 12, "text": "HOLGUIN"},
        {"id": 66, "provincia_id": 3, "text": "LISA"},
        {"id": 68, "provincia_id": 13, "text": "Jiguaní"},
        {"id": 69, "provincia_id": 12, "text": "Moa"},
        {"id": 70, "provincia_id": 13, "text": "Bayamo"},
        {"id": 71, "provincia_id": 10, "text": "CAMAGÜEY"},
        {"id": 72, "provincia_id": 7, "text": "Cienfuegos"},
        {"id": 73, "provincia_id": 5, "text": "Matanzas"},
        {"id": 74, "provincia_id": 1, "text": "Los Palacios"},
        {"id": 75, "provincia_id": 4, "text": "San José de las Lajas"}
      ],
    paises:[]
}