import axios from 'axios';
import { Global } from '@/utils/Global';
import popIt from '@/utils/toastr';

let URL = '';
let URL_MAP = '';
let URL_SIS = '';
let arrayStatus = [500,401,404]
let arrayError = ['ERR_NETWORK','ERR_BAD_REQUEST']

if (process.env.NODE_ENV === 'development') {
  URL = 'https://saldo.grin.cu/app_dev.php/api/';
  URL_MAP = 'https://testnegocio.grin.cu'
  URL_SIS = 'https://saldo.grin.cu/';
} else if (process.env.NODE_ENV === 'production') {
  const realDominio = (window.location.hostname === 'recarga.saldogrin.com')?true:false
  URL = (realDominio)?'https://saldogrin.com/api/':'https://saldo.grin.cu/api/';
  URL_MAP = (realDominio)?'https://negocio.saldogrin.com':'https://testnegocio.grin.cu';
  URL_SIS = (realDominio)?'https://saldogrin.com/':'https://saldo.grin.cu/';
}


const defaultHeaders={};

defaultHeaders['Content-Type'] = 'application/json';
defaultHeaders['customercode'] = 'recarga';

const http = axios.create({
  baseURL: URL
});
// Add a request interceptor
http.interceptors.request.use((config)=> {
  if (config.url !== 'generate-token') {
    if (Global.token!=null) {
      defaultHeaders['Authorization'] = `Bearer ${Global.token}`;
    }
    // if (Global.tokenRecaptcha!=null) {
    //   defaultHeaders['recaptchaToken'] = ` ${Global.tokenRecaptcha}`;
    // }    
    // Generar un nuevo token de reCAPTCHA antes de cada solicitud
    // Generar un nuevo token de reCAPTCHA antes de cada solicitud
    if (window.grecaptcha) {
      return window.grecaptcha.execute('6LeaOl8nAAAAAOE5CiJVVmCpE7UDXIlDNhSGw1rk', { action: 'submit' })
        .then((recaptchaToken) => {
          Global.tokenRecaptcha = recaptchaToken;
          defaultHeaders['recaptchaToken'] = recaptchaToken;
          config.headers = defaultHeaders;
          return config;
        })
        .catch((error) => {
          console.error('Error al generar el token de reCAPTCHA:', error);
          return Promise.reject(error);
        });
    }
  }
  config.headers = defaultHeaders
  return config;
}, (error)=> {
  // Do something with request error 
  const ruta = window.location.pathname; 
  if (arrayError.includes(error.code) || arrayStatus.includes(error.response.status)) {
    popIt.error('Error',error.response.data.message)
    if (ruta !== '/recarga') {
      // window.location.href = '/';     
    }
  }
  return Promise.reject(error);
});

// Add a response interceptor
http.interceptors.response.use( (response)=> {
  // Do something with response data
  return response;
}, (error)=> {
  // Do something with response error  
  const ruta = window.location.pathname;
  if (arrayError.includes(error.code) || arrayStatus.includes(error.response.status)) {
    console.log(error.response)
    popIt.error('Error',error.response.data.message)
    if (ruta !== '/recarga') {
      // window.location.href = '/';     
    }
  }
  if (ruta !== '/recarga') {
    // window.location.href = ruta;    
  }
  return Promise.reject(error);
});
export default { http, URL,URL_MAP,URL_SIS };
