
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

// Configuración global de toastr
toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-right', // Cambia 'toast-bottom-right' a 'toast-top-right'
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  };

const popIt = {
    warning: (msg)=>{
        return toastr.warning(
          msg
        )
    },
    success: (title, msg)=>{
        return toastr.success(          
          msg,
          title
        )
    },
    info: (title, msg)=>{
        return toastr.info(
          msg,
          title
        )
    },
    error: (title, msg)=>{
        return toastr.error(        
          msg,
          title
        )
    }
}

export default popIt;