// src/utils/loadRecaptcha.js

let grecaptchaPromise = null;

export function loadRecaptcha(siteKey) {
  if (grecaptchaPromise) {
    return grecaptchaPromise;
  }

  grecaptchaPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      if (typeof window.grecaptcha !== 'undefined') {
        resolve(window.grecaptcha);
      } else {
        reject(new Error('reCAPTCHA no se ha cargado correctamente.'));
      }
    };

    script.onerror = () => {
      reject(new Error('Error al cargar el script de reCAPTCHA.'));
    };

    document.head.appendChild(script);
  });
  return grecaptchaPromise;
}
