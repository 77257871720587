import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    start: {
      title: 'Recharge Grin Balance',
      sistem: 'GRIN BALANCE',
      subtitulo: 'Building financial bridges among loved ones',
      subtitulo_p: 'You no longer need to acquire foreign currency or receive remittances at an unfavorable exchange rate to shop in stores. With GRIN BALANCE, you can buy more and better at our establishments.',
      CAccount: 'Create Account',
      RBalance: 'Recharge Balance',
      Download: 'Download information',
      DComprar: 'Where to Buy',
      Visit: 'Visit the businesses that already have GRIN Balance',
      Address: 'Calzada 10 de Octubre between Dolores and Concepción. Lawton',
      Contact: 'Get in touch with us.',
      Contacts: 'Contacts',
      Start: 'Home',
      Log_in: 'Log in',
      Social: 'Follow us on our social networks',
      Map: 'See Map',
      Entity_Q: 'Are you a business owner and want to be part of the GRIN family?',
      Entity_R: 'To be part of the businesses associated with Saldo GRIN you only need to have a license that identifies you as an economic actor and contact our Support team. If you comply with all legal requirements, we will proceed to contract and determine costs for the implementation of the GRIN System in your business.',
      Steps_Q: 'Do you want to know how to use your GRIN Balance ($G)?',
      Steps_One: {
        title: 'Register in GRIN Balance',
        description: 'Access the registration form, fill out your information and create your account. Welcome to GRIN Balance!'
      },
      Steps_Two: {
        title: 'Access the GRIN System',
        description: 'You must access the GRIN System to manage your data and consult your GRIN Balance ($G).'
      },
      Steps_Three: {
        title: 'Recharge your GRIN Balance',
        description: 'Share this recharge link with family and friends abroad so they can recharge your GRIN Balance ($G) without having to access your account.'
      },
      Steps_Four: {
        title: 'Purchase with GRIN Balance',
        description: 'Use your GRIN Balance ($G) to shop at partner businesses. You can make your purchases in person or if the business allows it, buy remotely and reserve your merchandise.'
      },
      Windows_One: {
        title: 'Simple Use',
        description: 'Fast track for family and friends to recharge you from abroad. Share this link and wait for the recharge!!'
      },
      Windows_Two: {
        title: 'Profitable Purchases',
        description: 'Manage your purchases and supplies at a much more favorable and profitable rate than the MLC.'
      },
      Windows_Three: {
        title: 'Applied to Business',
        description: 'If you are a business owner, you can implement GRIN Balance ($G) and take advantage of all its Advantages and Benefits.'
      }
    },
    axios: {
      generate_token_error: 'Error loading data',
      generate_token_error_title: 'Error!',
      generate_token_success: 'Data has been loaded',
      generate_token_success_title: 'Information'
    },
    sms: {
      success: 'The operation was successful',
      failed: 'The operation was not successful',
      info: 'Information',
      warning: 'Warning',
      error: 'Error!',
    },
    Create_Account: {
      title: 'Check in',
      radio_1: 'NATURAL PERSON',
      radio_2: 'BUSINESS',
      name: 'Name',
      surname: '1st Surname',
      secound_surname: '2nd Surname',
      email: 'Email',
      password: 'Password',
      confirm_password: 'Confirm Password',
      mobile: 'Mobile Phone',
      province: 'Province',
      municipality: 'Municipality',
      select: 'Select',
      address: 'Private Address',
      ci: 'Identity Card',
      message: {
        exitUser: "Ya existe un usuario asociado al correo: <strong>{email}</strong>. Si es usted el titular del correo entonces debes acceder a este para revisar tus credenciales.",
        searchClient: "No existe un cliente con dicho Correo Electr&oacute;nico. Por favor reg&iacute;strate.",
        validarRecarga: "Datos Incorrectos, verifique la informaci&oacute;n insertada.",
        infoNewUser: "Hola",
        welcome: "Bienvenido(a) a la comunidad de Saldo GRIN",
        mensajeFijo: "Tu CUENTA ha sido creada correctamente!!... asociada al correo: {email}.",
        mensajeFijo3: "<br>Ante cualquier duda o inquietud puedes acudir a nuestro equipo de soporte y con gusto ser&aacute;s atendido.<br> <strong>Contactos:</strong> {contact} <strong>Correo:</strong> {email}. ",
        mensajeFijo2: "<br>Te enviaremos un correo con todos los detalles antes descritos. Por favor revise su correo en la <strong>Bandeja de Recibido</strong> o en <strong>SPAM</strong>.",
      }


    },
    Recharge_Balance: {
      amount: 'Amount in EUR',
      Payment: 'Payment Method',
      Amount_Recharge: 'Amount to Recharge',
      Recharge: 'Recharge',
      mail: 'Mail',
      Available: 'Available Balance',
      Client_Data: 'Client Data',
      name: 'Name and Surname',
    }
  },
  es: {
    start: {
      title: 'Recarga Saldo Grin',
      sistem: 'SALDO GRIN',
      subtitulo: 'Construyendo puentes financieros entre seres queridos',
      subtitulo_p: 'Ya no necesitas adquirir MLC o recibir remesas con una tasa de cambio desfavorable para comprar en las tiendas. Con Saldo GRIN puedes comprar más y mejor en nuestros establecimientos.',
      CAccount: 'Crear cuenta',
      RBalance: 'Recarga saldo',
      Download: 'Descargar información',
      DComprar: 'Donde Comprar',
      Visit: 'Visita los negocios que ya tienen Saldo GRIN',
      Address: 'Calzada 10 de Octubre entre Dolores y Concepción. Lawton',
      Contact: 'Póngase en contacto con nosotros',
      Contacts: 'Contactos',
      Start: 'Inicio',
      Log_in: 'Iniciar sesión',
      Social: 'Síguenos en nuestras redes sociales',
      Map: 'Ver Mapa',
      Entity_Q: '¿Eres propietario de una empresa y quieres ser parte de la familia GRIN?',
      Entity_R: 'Para formar parte de los negocios asociados a Saldo GRIN solo necesitas tener una licencia que te identifique como actor económico y contactar con nuestro equipo de Soporte. Si cumples con todos los requisitos legales, procederemos a la contratación y determinación de costes para la implementación del Sistema GRIN en tu negocio.',
      Steps_Q: '¿Quiéres saber cómo usar tu Saldo GRIN ($G)?',
      Steps_One: {
        title: 'Registrate en Saldo GRIN',
        description: 'Accede al formulario de registro, llena tus datos y crea tu cuenta. ¡Bienvenido(a) a Saldo GRIN!'
      },
      Steps_Two: {
        title: 'Accede al Sistema GRIN',
        description: 'Debes acceder al Sistema GRIN para gestionar tus datos y consultar tu Saldo GRIN ($G).'
      },
      Steps_Three: {
        title: 'Recarga tu Saldo GRIN',
        description: 'Comparte este link de recarga con familiares y amigos en el exterior para que puedan recargar tu Saldo GRIN ($G) sin necesidad de acceder a tu cuenta.'
      },
      Steps_Four: {
        title: 'Compra con Saldo GRIN',
        description: 'Usa tu Saldo GRIN ($G) para comprar en los negocios asociados. Puedes hacer tus compras de forma presencial o si el negocio lo permite, comprar a distancia y reservar tu mercancía.'
      },
      Windows_One: {
        title: 'Uso Simple',
        description: 'Vía rápida para que familiares y amigos te recarguen desde el exterior. ¡¡Comparte este link y espera la recarga!!.'
      },
      Windows_Two: {
        title: 'Compras Rentables',
        description: 'Gestiona tus compras y suministros a una tasa mucho más favorable y rentable que el MLC.'
      },
      Windows_Three: {
        title: 'Aplicado a Negocios',
        description: 'Si eres propietario de un negocio, puedes implementar Saldo GRIN ($G) y aprovechar todas sus Ventajas y Beneficios.'
      }
    },
    axios: {
      generate_token_error: 'Error al cargar los datos',
      generate_token_error_title: 'Error!',
      generate_token_success: 'Se han cargados los datos',
      generate_token_success_title: 'Información'
    },
    sms: {
      success: 'La operación se realizó con exito',
      failed: 'La operación no fue exitosa',
      info: 'Información',
      warning: 'Advertencia',
      error: 'Error!',
      welcome: 'Bienvenido(a)',
      question: ''
    },
    Create_Account: {
      title: 'Registrarse',
      radio_1: 'PERSONA NATURAL',
      radio_2: 'NEGOCIO',
      name: 'Nombre',
      surname: '1er Apellido',
      secound_surname: '2do Apellido',
      email: 'Correo Electronico',
      password: 'Contraseña',
      confirm_password: 'Confirmar Contraseña',
      mobile: 'Teléfono',
      province: 'Provincia',
      municipality: 'Municipio',
      select: 'Seleccione',
      address: 'Dirección Particular',
      ci: 'Carnet de Identidad',
      message: {
        exitUser: "Ya existe un usuario asociado al correo: <strong>{email}</strong>. Si es usted el titular del correo entonces debes acceder a este para revisar tus credenciales.",
        searchClient: "No existe un cliente con dicho Correo Electr&oacute;nico. Por favor reg&iacute;strate.",
        validarRecarga: "Datos Incorrectos, verifique la informaci&oacute;n insertada.",
        infoNewUser: "Hola",
        welcome: "Bienvenido(a) a la comunidad de Saldo GRIN",
        mensajeFijo: "Tu CUENTA ha sido creada correctamente!!... asociada al correo: {email}.",
        mensajeFijo3: "<br>Ante cualquier duda o inquietud puedes acudir a nuestro equipo de soporte y con gusto ser&aacute;s atendido.<br> <strong>Contactos:</strong> {contact} <strong>Correo:</strong> {email}. ",
        mensajeFijo2: "<br>Te enviaremos un correo con todos los detalles antes descritos. Por favor revise su correo en la <strong>Bandeja de Recibido</strong> o en <strong>SPAM</strong>.",
      }
    },
    Recharge_Balance: {
      amount: 'Monto en EUR',
      Payment: 'Método de Pago',
      Amount_Recharge: 'Importe a Recargar',
      Recharge: 'Recargar',
      mail: 'Correo',
      Available: 'Saldo Disponible',
      Client_Data: 'Datos del Cliente',
      name: 'Nombre y Apellidos',
    }
  },
  it: {
    start: {
      title: 'Ricarica Saldo Grin',
      sistem: 'SALDO GRIN',
      subtitulo: 'Costruzione di ponti finanziari tra persone care',
      subtitulo_p: 'Non è più necessario acquistare MLC o ricevere remittance con un tasso di cambio sfavorevole per fare acquisti nei negozi. Con Saldo GRIN puoi comprare di più e meglio nei nostri stabilimenti.',
      CAccount: 'Crea Account',
      RBalance: 'Ricarica Saldo',
      Download: 'Scarica informazioni',
      DComprar: 'Dove Comprare',
      Visit: 'Visita i negozi che utilizzano già Saldo GRIN',
      Address: 'Calzada 10 de Octubre tra Dolores e Concepción. Lawton',
      Contact: 'Contattaci',
      Contacts: 'Contatti',
      Start: 'Inizio',
      Log_in: 'Accedi',
      Social: 'Seguici sui nostri social media',
      Map: 'Visualizza Mappa',
      Entity_Q: "Sei proprietario di un'impresa e vuoi far parte della famiglia GRIN?",
      Entity_R: "Per far parte dei business associati a Saldo GRIN, è sufficiente avere una licenza che ti identifichi come attore economico e contattare il nostro team di supporto. Se soddisfi tutti i requisiti legali, procederemo con l'assunzione e la determinazione dei costi per l'implementazione del Sistema GRIN nel tuo business.",
      Steps_Q: 'Vuoi sapere come usare il tuo Saldo GRIN ($G)?',
      Steps_One: {
        title: 'Registrati su Saldo GRIN',
        description: 'Accedi al modulo di registrazione, inserisci i tuoi dati e crea il tuo account. Benvenuto su Saldo GRIN!'
      },
      Steps_Two: {
        title: 'Accedi al Sistema GRIN',
        description: 'Devi accedere al Sistema GRIN per gestire i tuoi dati e consultare il tuo Saldo GRIN ($G).'
      },
      Steps_Three: {
        title: 'Ricarica il tuo Saldo GRIN',
        description: "Condividi questo link di ricarica con familiari e amici all'estero in modo che possano ricaricare il tuo Saldo GRIN ($G) senza bisogno di accedere al tuo account."
      },
      Steps_Four: {
        title: 'Compra con Saldo GRIN',
        description: 'Usa il tuo Saldo GRIN ($G) per acquistare nei business associati. Puoi fare acquisti di persona oppure, se il business lo permette, acquistare a distanza e prenotare la tua merce.'
      },
      Windows_One: {
        title: 'Utilizzo Semplice',
        description: "Un modo rapido per far ricaricare il tuo Saldo GRIN da familiari e amici all'estero. Condividi questo link e aspetta la ricarica!"
      },
      Windows_Two: {
        title: 'Acquisti Convenienti',
        description: 'Gestisci i tuoi acquisti e approvvigionamenti con un tasso di cambio molto più favorevole e conveniente rispetto al MLC.'
      },
      Windows_Three: {
        title: 'Applicazione per Business',
        description: 'Se sei proprietario di un business, puoi implementare Saldo GRIN ($G) e sfruttarne tutti i vantaggi e i benefici.'
      }
    },
    axios: {
      generate_token_error: 'Errore nel caricamento dei dati',
      generate_token_error_title: 'Errore!',
      generate_token_success: 'I dati sono stati caricati',
      generate_token_success_title: 'Informazione'
    },
    sms: {
      success: 'Operazione completata con successo',
      failed: 'Operazione non riuscita',
      info: 'Informazione',
      warning: 'Aviso',
      error: 'Error!',
      welcome: 'Benvenuto(a)',
    },
    Create_Account: {
      title: 'Registrati',
      radio_1: 'PERSONA FISICA',
      radio_2: 'BUSINESS',
      name: 'Nome',
      surname: 'Cognome',
      secound_surname: 'Secondo Cognome',
      email: 'Indirizzo Email',
      password: 'Password',
      confirm_password: 'Conferma Password',
      mobile: 'Telefono',
      province: 'Provincia',
      municipality: 'Municipio',
      select: 'Seleziona',
      address: 'Indirizzo',
      ci: 'Carta d\'Identità',
      message: {
        exitUser: "Ya existe un usuario asociado al correo: <strong>{email}</strong>. Si es usted el titular del correo entonces debes acceder a este para revisar tus credenciales.",
        searchClient: "No existe un cliente con dicho Correo Electr&oacute;nico. Por favor reg&iacute;strate.",
        validarRecarga: "Datos Incorrectos, verifique la informaci&oacute;n insertada.",
        infoNewUser: "Hola",
        welcome: "Bienvenido(a) a la comunidad de Saldo GRIN",
        mensajeFijo: "Tu CUENTA ha sido creada correctamente!!... asociada al correo: {email}.",
        mensajeFijo3: "<br>Ante cualquier duda o inquietud puedes acudir a nuestro equipo de soporte y con gusto ser&aacute;s atendido.<br> <strong>Contactos:</strong> {contact} <strong>Correo:</strong> {email}. ",
        mensajeFijo2: "<br>Te enviaremos un correo con todos los detalles antes descritos. Por favor revise su correo en la <strong>Bandeja de Recibido</strong> o en <strong>SPAM</strong>.",
      }

    },
    Recharge_Balance: {
      amount: 'Importo in EUR',
      Payment: 'Metodo di Pagamento',
      Amount_Recharge: 'Importo da Ricaricare',
      Recharge: 'Ricarica',
      mail: 'Email',
      Available: 'Saldo Disponibile',
      Client_Data: 'Dati del Cliente',
      name: 'Nome e Cognome',
    }
  }

};

const i18n = createI18n({
  locale: 'es',
  escapeHtml: true,
  strictMessage: false,
  messages,
});

export default i18n;
