<template>  


<div class="container px-4 py-5 ventajas" id="featured-3">
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <svg class="bi" width="1.2em" height="1.2em" svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 290.000000 276.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
              <path d="M1130 2558 c-19 -20 -20 -34 -20 -200 0 -177 0 -179 25 -203 29 -30
68 -32 95 -5 19 19 20 33 20 208 0 170 -2 191 -18 205 -26 24 -77 21 -102 -5z" />
              <path d="M750 2330 c-37 -37 -27 -77 38 -142 43 -42 66 -58 86 -58 30 0 76 40
76 65 0 29 -29 72 -76 114 -53 46 -92 53 -124 21z" />
              <path d="M1476 2291 c-48 -52 -57 -66 -54 -92 4 -42 46 -74 83 -65 34 9 123
98 131 133 10 37 -24 77 -68 81 -30 3 -40 -4 -92 -57z" />
              <path d="M2000 2309 c-33 -15 -139 -114 -413 -387 -274 -273 -370 -363 -376
-353 -5 7 -14 41 -21 75 -29 138 -66 208 -135 256 -58 40 -145 64 -203 55 -47
-6 -133 -42 -163 -67 -38 -31 -39 -46 -39 -509 l0 -451 -115 -118 c-92 -94
-115 -123 -115 -146 0 -24 41 -69 253 -282 l253 -253 385 3 384 3 55 29 c77
39 669 628 705 700 53 106 17 233 -81 291 l-51 30 -5 60 c-8 95 -47 154 -131
197 -33 16 -38 23 -33 43 3 13 1 48 -5 77 -9 44 -20 62 -66 106 l-55 54 134
136 c138 141 158 173 158 248 0 162 -171 270 -320 203z m153 -151 c9 -11 17
-36 17 -56 0 -34 -19 -55 -324 -362 -216 -216 -330 -337 -340 -362 -49 -116
20 -266 137 -299 53 -15 61 -24 76 -77 18 -63 84 -126 150 -143 42 -11 53 -17
57 -38 11 -54 44 -110 84 -142 l41 -33 -168 -169 c-92 -93 -182 -175 -199
-182 -23 -11 -110 -14 -361 -16 -182 -1 -337 2 -344 7 -8 5 -97 94 -199 197
l-184 188 100 100 99 100 -4 448 c-3 445 -3 449 18 470 28 28 103 29 149 1 48
-29 66 -66 96 -202 49 -215 51 -222 111 -287 73 -78 98 -136 98 -226 0 -79
-21 -139 -74 -205 -40 -49 -43 -94 -10 -122 38 -30 70 -23 116 26 147 153 152
412 12 589 l-30 39 384 385 c211 211 393 389 404 393 28 13 67 3 88 -22z
m-168 -593 c16 -16 25 -35 25 -57 0 -28 -17 -49 -133 -165 -149 -149 -165
-157 -218 -104 -53 53 -40 78 119 234 102 99 125 117 152 117 20 0 40 -9 55
-25z m160 -270 c18 -17 25 -35 25 -61 0 -33 -10 -46 -87 -126 -124 -127 -153
-139 -204 -89 -51 51 -43 71 79 193 119 119 142 129 187 83z m173 -281 c38
-43 30 -68 -55 -155 -67 -69 -81 -79 -112 -79 -47 0 -81 32 -81 77 0 28 11 46
58 95 97 103 140 117 190 62z" />
            </g>

          </svg>
        </div>
        <h3 class="fs-2 text-body-emphasis">{{ $t('start.Windows_One.title') }}</h3>
        <p>{{ $t('start.Windows_One.description') }}
        </p>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <svg class="bi" width="1.2em" height="1.2em" version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 290.000000 276.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
              <path d="M1355 2648 c-89 -91 -126 -163 -133 -257 -3 -54 0 -77 16 -114 28
         -63 64 -109 112 -142 33 -23 40 -33 40 -61 0 -19 -2 -34 -5 -34 -3 0 -26 15
         -52 34 -83 58 -152 78 -291 84 -108 4 -125 2 -142 -13 -55 -50 7 -279 103
         -377 78 -81 202 -119 325 -101 l62 10 0 -63 c0 -61 -1 -64 -27 -69 -145 -30
         -248 -77 -336 -156 -70 -62 -142 -169 -176 -262 -23 -64 -26 -86 -26 -207 0
         -121 3 -143 26 -207 57 -154 170 -283 314 -354 186 -92 375 -91 565 2 113 56
         221 162 273 270 125 254 82 535 -112 729 -103 103 -197 151 -364 185 -27 6
         -28 7 -25 68 l3 62 40 -5 c78 -10 170 -2 227 20 135 50 232 208 240 389 4 79
         -7 84 -162 78 -140 -4 -200 -22 -291 -83 -28 -19 -53 -34 -55 -34 -2 0 -4 13
         -4 29 0 23 13 42 56 83 81 77 108 134 109 228 0 65 -4 84 -33 143 -41 84 -146
         197 -182 197 -17 0 -45 -22 -95 -72z m170 -172 c46 -92 25 -181 -58 -246 l-28
         -22 -43 44 c-81 80 -80 163 4 274 l42 56 29 -28 c16 -15 40 -50 54 -78z m-340
         -457 c62 -23 108 -56 141 -100 26 -36 60 -117 52 -125 -13 -13 -150 -16 -192
         -4 -86 24 -163 119 -182 224 l-7 39 69 -8 c38 -4 92 -16 119 -26z m705 11 c0
         -40 -44 -135 -82 -176 -54 -58 -116 -79 -216 -71 -40 3 -76 8 -79 11 -12 12
         32 102 71 144 48 52 98 79 180 97 93 20 126 19 126 -5z m-309 -616 c182 -54
         316 -193 364 -379 66 -257 -98 -531 -364 -609 -30 -9 -91 -16 -136 -16 -139 0
         -244 42 -350 141 -80 76 -137 181 -155 291 -41 239 111 484 350 565 80 28 210
         31 291 7z" />
              <path d="M1417 1329 c-10 -6 -22 -21 -27 -34 -5 -13 -24 -32 -42 -41 -77 -39
         -118 -103 -118 -186 0 -100 70 -180 175 -199 92 -18 115 -29 132 -65 14 -27
         14 -37 3 -64 -14 -34 -55 -60 -95 -60 -57 0 -97 39 -107 105 -2 10 -15 26 -29
         34 -24 13 -29 13 -53 -2 -65 -43 -7 -187 94 -232 29 -13 40 -24 42 -43 2 -16
         13 -32 29 -41 24 -13 29 -13 53 2 16 11 26 27 26 41 0 17 8 27 28 34 41 14
         103 71 118 109 17 39 18 121 3 160 -26 69 -125 133 -206 133 -59 0 -114 61
         -98 109 10 32 63 71 96 71 61 0 100 -37 111 -105 2 -10 15 -26 29 -34 24 -13
         29 -13 53 2 50 33 30 135 -39 196 -21 18 -51 38 -67 43 -16 6 -28 17 -28 27 0
         21 -30 51 -51 51 -8 0 -22 -5 -32 -11z" />
              <path d="M642 964 c-19 -13 -22 -24 -22 -75 0 -136 55 -305 141 -434 51 -76
         158 -183 218 -219 l36 -21 -272 -5 c-195 -4 -275 -8 -284 -17 -19 -19 -15 -74
         6 -89 16 -12 186 -14 982 -14 950 0 963 0 983 20 25 25 25 55 0 80 -19 19 -34
         20 -282 20 l-263 1 35 27 c210 162 333 378 347 610 5 80 4 95 -12 112 -21 24
         -43 25 -71 5 -17 -11 -22 -29 -28 -94 -31 -375 -339 -661 -711 -661 -192 0
         -355 69 -496 209 -132 132 -197 269 -214 454 -5 63 -11 80 -28 92 -27 19 -37
         19 -65 -1z" />
            </g>
          </svg>
        </div>
        <h3 class="fs-2 text-body-emphasis">{{ $t('start.Windows_Two.title') }}</h3>
        <p>{{ $t('start.Windows_Two.description') }}</p>
      </div>
      <div class="feature col">
        <div
          class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <svg class="bi" width="1.2em" height="1.2em" svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 290.000000 276.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
              <path d="M2235 2679 c-150 -18 -309 -119 -375 -237 l-22 -39 -89 -7 c-49 -4
         -339 -6 -644 -5 -520 1 -557 0 -592 -18 -66 -33 -77 -58 -84 -201 l-6 -127
         -104 -215 c-94 -195 -104 -220 -103 -268 2 -77 50 -169 119 -229 l55 -46 2
         -588 c2 -466 6 -591 16 -601 10 -10 190 -13 923 -13 890 0 911 0 925 19 12 17
         14 115 14 601 l0 581 59 52 c81 71 116 142 115 237 l0 71 66 27 c128 52 239
         168 290 304 28 73 38 218 20 294 -43 186 -192 344 -370 392 -73 20 -142 25
         -215 16z m203 -94 c125 -38 247 -159 290 -288 54 -166 9 -342 -120 -464 -63
         -58 -114 -87 -195 -108 -156 -41 -314 6 -428 126 -80 84 -118 170 -123 280
         -10 209 107 383 302 451 65 22 203 24 274 3z m-652 -326 c-3 -17 -6 -60 -6
         -95 l0 -64 -613 0 c-463 0 -616 3 -625 12 -7 7 -12 39 -12 75 0 112 -58 103
         651 103 l612 0 -7 -31z m-913 -284 c-7 -60 -74 -320 -85 -327 -18 -11 -425
         -10 -432 1 -5 8 135 313 156 339 8 9 54 12 187 10 169 -3 176 -4 174 -23z
         m405 -149 c2 -129 -1 -171 -10 -178 -19 -12 -346 -10 -353 2 -5 8 53 288 70
         338 4 10 38 12 147 10 l143 -3 3 -169z m401 152 c18 -51 72 -310 67 -323 -4
         -12 -36 -15 -174 -15 -117 0 -172 4 -180 12 -13 13 -18 301 -6 332 5 14 28 16
         145 16 136 0 140 -1 148 -22z m155 -54 c48 -102 133 -187 239 -239 42 -21 77
         -40 77 -42 0 -6 -265 -2 -277 5 -17 11 -94 352 -79 352 2 0 20 -34 40 -76z
         m-1067 -399 c3 -9 -8 -37 -23 -62 -55 -89 -184 -128 -282 -87 -65 27 -141 116
         -128 150 8 22 424 21 433 -1z m509 0 c10 -25 -4 -59 -38 -94 -89 -92 -224 -96
         -322 -10 -46 41 -55 64 -36 100 10 18 23 19 201 19 159 0 191 -2 195 -15z
         m495 5 c32 -18 24 -61 -18 -102 -49 -47 -99 -68 -165 -68 -68 1 -123 26 -170
         78 -39 44 -47 69 -26 90 15 15 350 17 379 2z m559 -8 c0 -10 -13 -39 -29 -64
         -55 -87 -189 -124 -286 -79 -66 30 -138 122 -119 152 3 5 101 9 220 9 200 0
         214 -1 214 -18z m-1472 -192 c101 -80 235 -98 349 -46 34 15 72 39 84 52 30
         32 40 30 93 -10 126 -96 286 -96 412 0 24 19 49 34 55 34 6 0 25 -12 43 -27
         49 -41 124 -72 197 -79 l67 -6 4 -517 c2 -284 0 -524 -4 -533 -7 -14 -22 -17
         -82 -17 -47 0 -78 5 -85 12 -6 8 -11 113 -13 270 l-3 257 -28 27 -27 28 -177
         3 c-278 5 -266 20 -273 -333 l-5 -260 -460 -3 c-253 -1 -470 0 -482 3 l-23 5
         0 529 0 528 64 7 c87 9 147 31 193 71 21 19 44 34 51 35 7 0 30 -13 50 -30z
         m1010 -662 c17 -17 17 -459 0 -476 -13 -13 -242 -18 -272 -6 -14 5 -16 36 -16
         244 0 181 3 240 13 243 27 12 263 7 275 -5z" />
              <path d="M2292 2503 c-7 -3 -19 -18 -27 -34 -9 -16 -22 -29 -29 -29 -21 0 -74
         -48 -91 -81 -19 -35 -19 -100 -1 -143 20 -49 75 -86 139 -95 91 -12 129 -52
         113 -116 -9 -37 -63 -67 -105 -61 -43 7 -66 29 -83 81 -14 42 -38 55 -63 35
         -41 -34 4 -141 72 -174 29 -15 44 -29 48 -48 11 -48 75 -45 75 4 0 12 12 24
         33 33 44 20 61 35 86 75 24 41 28 108 7 148 -29 56 -103 102 -165 102 -44 0
         -91 44 -91 85 0 46 41 85 91 85 45 0 99 -40 99 -73 0 -35 15 -49 46 -45 78 9
         17 157 -78 190 -17 6 -28 17 -28 29 0 20 -28 39 -48 32z" />
              <path d="M642 1118 c-9 -9 -12 -80 -12 -260 0 -238 1 -248 20 -258 14 -7 117
         -10 311 -8 244 3 291 5 299 18 15 23 13 493 -2 508 -17 17 -599 17 -616 0z
         m246 -101 c12 -14 10 -76 -2 -96 -4 -6 -34 -11 -71 -11 -88 0 -111 26 -85 94
         9 24 14 26 79 26 44 0 72 -5 79 -13z m271 -1 c7 -8 11 -34 9 -58 l-3 -43 -68
         -3 c-42 -2 -74 2 -83 9 -14 12 -20 90 -7 102 13 14 140 8 152 -7z m-269 -225
         c14 -26 13 -64 -2 -79 -7 -7 -42 -12 -85 -12 l-73 0 0 48 c0 27 3 52 7 55 3 4
         37 7 74 7 57 0 70 -3 79 -19z m275 -36 l0 -50 -80 0 -80 0 -3 39 c-5 64 -2 67
         85 64 l78 -3 0 -50z" />
            </g>
          </svg>
        </div>
        <h3 class="fs-2 text-body-emphasis">{{ $t('start.Windows_Three.title') }}</h3>
        <p>{{ $t('start.Windows_Three.description') }}</p>
      </div>
    </div>
  </div>
    
</template>
    
<script>

export default {
    name: 'Windows-View'
}
</script>
        