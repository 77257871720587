import { createRouter, createWebHistory } from 'vue-router';

import { actualizarIdioma } from '../utils/infoProvider';

import Home from '../components/Home.vue'
import Recarga from '../components/Recarga.vue'
import Register from '../components/Register.vue'
import Success from '../components/Success.vue'
import Failed from '../components/Failed.vue'
// import Mapa from '../components/Mapa.vue'

const IdiomaPermitido = ['es','en','it'];
const routes = [
    // { path: '/', name: 'Home', component: Home },
    { path: '/:idioma', name: 'HomeIdioma', component: Home },
    { path: '/:idioma/recarga', name: 'Recarga', component: Recarga },
    { path: '/:idioma/register', name: 'Register', component: Register },
    // { path: '/mapa', name: 'Mapa', component: Mapa },
    { path: '/:idioma/tropipay/Success', name: 'Success', component: Success },
    { path: '/:idioma/tropipay/Failed', name: 'Failed', component: Failed }
  ]
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
 
  router.beforeEach(async (to, from, next) => {  
    scrollToTop()
    // Si no hay idioma en la URL, redirige a la URL con el idioma
    if (!to.params.idioma) {
      const idioma = obtenerIdiomaDelNavegador();
      const finalIdioma = actualizarIdiomaPermitido(idioma)
      // Evitar redirigir si el idioma ya coincide con el idioma actual
      if (finalIdioma === idioma) {
        next(`/${idioma}`);
      } else {
        next(`/${finalIdioma}`);
      }    
    } else {
      const finalIdioma = actualizarIdiomaPermitido(to.params.idioma)
      // Evitar redirigir si el idioma ya coincide con el idioma actual
      if (finalIdioma != to.params.idioma) {
        next(`/${finalIdioma}`);
      } else {
        next();
      }
    }
  });

  function scrollToTop() {
    if ('scrollBehavior' in document.documentElement.style) {
      // Si el navegador admite el comportamiento de desplazamiento
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Opcional: desplazamiento suave
      });
    } else {
      // Si el navegador no admite el comportamiento de desplazamiento
      window.scrollTo(0, 0);
    }
  }

  function obtenerIdiomaDelNavegador() {
    return navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage || window.navigator.languages[0] || 'es';
  }

  function actualizarIdiomaPermitido(idioma) {
    if (IdiomaPermitido.includes(idioma)) {       
      actualizarIdioma(idioma);
      return idioma;
    } else {
      actualizarIdioma('es');
      return 'es';
    }
  }
  
  export default router