<template>
	<loading v-model:active="isLoading" :can-cancel="false" :is-full-page=true color="rgba(10, 81, 35, 1)" loader="dots"
		:width=100 :height=100 :opacity=0.5 :zIndex=999 backgroundColor="#6c757d" />

	<section class="ftco-section">
		<div class="container">

			<div class="row justify-content-center">
				<div class="col-md-7 col-lg-6">
					<div class="wrap">
						<div class="login-wrap p-4 p-md-5">
							<div class="d-flex">
								<div class="w-100">
									<h2>{{ $t('Create_Account.title') }}</h2>
								</div>

							</div>
							<form @submit.prevent="enviarDatos" class="signin-form">
								<div class="row">
									<div class="col-md-12 col-lg-12">
										<div class="form-group mt-3">
											<div
												class="row  justify-content-center flex-row flex-md-nowrap flex-sm-nowrap text-center mb-3">
												<div class="col-lg-12 col-md-12 col-sm-12">
													<div
														class="form-check form-check-inline col-lg-5 col-md-3 col-sm-1">
														<input type="radio" id="opcion2" value="persona"
															class="form-check-input" v-model="form.user.selectedOption"
															@change="SelectionRadio" />
														<label class="form-check-label" for="opcion2"><strong>{{
		$t('Create_Account.radio_1') }}</strong></label>
													</div>
													<div
														class="form-check form-check-inline col-lg-5 col-md-3 col-sm-1">
														<input type="radio" id="opcion1" value="negocio"
															v-model="form.user.selectedOption" class="form-check-input"
															@change="SelectionRadio" />
														<label class="form-check-label" for="opcion1"><strong>{{
		$t('Create_Account.radio_2') }}</strong></label>
													</div>
												</div>
											</div>
										</div>
									</div>									
									<div class="col-md-7 col-lg-6">
										<div class="form-group mt-3">
											<input type="email" class="form-control" id="email" @input="handleInputChange" required  @blur="searchCorreo"
												v-model="form.user.correo" autocomplete="off">
											<label class="form-control-placeholder" for="email">{{
		$t('Create_Account.email') }}</label>
											<span toggle="#email"
												class="fa fa-fw fa-envelope field-icon toggle-password"
												:style="(isEmailValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6" v-if="negocio">
										<div class="form-group mt-3">
											<input type="text" class="form-control" :maxlength="11" required id="NIT"  :disabled="readonly"
												v-model="form.user.NIT" @input="validateNIT" autocomplete="off">
											<label class="form-control-placeholder" for="NIT">{{ $t('Create_Account.ci')
												}}</label>
											<span toggle="#NIT" class="fa fa-fw fa-user field-icon toggle-password"
												:style="(isNITValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6">
										<div class="form-group mt-3">
											<input type="text" class="form-control" required id="nombre" :disabled="readonly"
												v-model="form.user.nombre" @input="validarText" autocomplete="off">
											<label class="form-control-placeholder" for="nombre">{{
		$t('Create_Account.name') }}</label>
											<span toggle="#nombre" class="fa fa-fw fa-user field-icon toggle-password"
												:style="(isNombreValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6">
										<div class="form-group mt-3">
											<input type="text" class="form-control" required id="pappell" :disabled="readonly"
												v-model="form.user.papell" @input="validarText" autocomplete="off">
											<label class="form-control-placeholder" for="pappell">{{
		$t('Create_Account.surname') }}</label>
											<span toggle="#pappell" class="fa fa-fw fa-user field-icon toggle-password"
												:style="(isPappellValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6">
										<div class="form-group mt-3">
											<input type="text" class="form-control" required id="sappell" :disabled="readonly"
												v-model="form.user.sapell" @input="validarText" autocomplete="off">
											<label class="form-control-placeholder" for="sappell">{{
		$t('Create_Account.secound_surname') }}</label>
											<span toggle="#sappell" class="fa fa-fw fa-user field-icon toggle-password"
												:style="(isSappellValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6" v-if="!negocio">
										<div class="form-group mt-3">
											<select class="form-control form-select" id="pais" v-model="form.user.pais" autofocus="false"
												@change="searchPais()">
												<!-- Opciones para el select de países -->
												<option value="">--- {{ $t('Create_Account.select') }} ---</option>
												<option v-for="(pais) in OptionsSelectPais" :key="pais.id"
													:value="pais.id">
													{{ pais.name.toUpperCase() }}
												</option>
												<!-- Agrega más opciones según sea necesario -->
											</select>
											<label class="form-control-placeholder">País:</label>
										</div>
									</div>
									<div class="col-md-3 col-lg-2">
										<div class="form-group mt-3">
											<input type="tel" readonly="true" class="form-control"
												v-model="form.user.identificadorPais" required>
										</div>
									</div>
									<div class="col-md-5 col-lg-4">
										<div class="form-group mt-3">
											<input type="text"  class="form-control" v-model="form.user.telefonoMovil" required
												id="telefono" @input="validateMobileNumber(maxlength)" :maxlength="maxlength"  :disabled="readonly"
												autocomplete="off">
											<label class="form-control-placeholder" for="telefono"> {{ $t('Create_Account.mobile')
												}}</label>
											<span toggle="#telefono"
												class="fa fa-fw fa-phone field-icon toggle-password"
												:style="(isMobileNumberValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6" v-if="negocio">
										<div class="form-group mt-3">
											<select class="form-control form-select" :id="'provincia'" autofocus="false"
												@change="getProvMun($event, 'provincia')">
												<option value="">--- {{ $t('Create_Account.select') }} ---</option>
												<option v-for="option in OptionsSelectProvincia" v-bind:key="option.id"
													:value="option.id">{{ option.text.toUpperCase() }}</option>
											</select>
											<label class="form-control-placeholder" for="municipio">{{
		$t('Create_Account.province') }}</label>
										</div>
									</div>
									<div class="col-md-7 col-lg-6" v-if="negocio">
										<div class="form-group mt-3" disabled>
											<select class="form-control form-select" :id="'municipio'" autofocus="false"
												@change="getProvMun($event, 'municipio')">
												<option value="">--- {{ $t('Create_Account.select') }} ---</option>
												<option v-for="option in OptionsSelectMunicipio" v-bind:key="option.id"
													:value="option.id">{{ option.text.toUpperCase() }}</option>
											</select>
											<label class="form-control-placeholder" for="municipio">{{
		$t('Create_Account.municipality') }}</label>
										</div>
									</div>
									<div class="col-md-7 col-lg-12" v-if="negocio" >
										<div class="form-group mt-3">
											<textarea class="form-control" name="direccion" id="direccion" rows="5" :disabled="readonly"
												required v-model="form.user.direccion" @input="validarText"
												autocomplete="off"></textarea>
											<label class="form-control-placeholder" for="direccion">{{
		$t('Create_Account.address') }}</label>
											<span toggle="#direccion"
												class="fa fa-fw fa-home field-icon toggle-password"
												:style="(isDireccionValid) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6">
										<div class="form-group mt-3">
											<input id="password" :type="type" class="form-control" :disabled="readonly"
												onpaste="return false;" oncut="return false;" oncopy="return false;"
												required v-model="form.user.password" @input="checkPasswordMatch"
												autocomplete="off">
											<label class="form-control-placeholder" for="password">{{
		$t('Create_Account.password') }}</label>
											<span toggle="#password" class="fa fa-fw fa-eye field-icon toggle-password"
												:class="eye" style="margin-right: 25px;"
												@click="cambioType('password')"></span>
											<span toggle="#password" class="fa fa-fw fa-key field-icon toggle-password"
												:style="(disabled) ? 'color: red;' : 'color: rgba(10, 81, 35, 1)'"></span>
										</div>
									</div>
									<div class="col-md-7 col-lg-6">
										<div class="form-group mt-3">
											<input id="configpassword" :type="typeconfir" class="form-control"
												onpaste="return false;" oncut="return false;" oncopy="return false;"
												required v-model="form.user.configpassword" @input="checkPasswordMatch"
												:disabled="disabled" autocomplete="off">
											<label class="form-control-placeholder" for="configpassword">{{
		$t('Create_Account.confirm_password') }}</label>
											<span toggle="#password" class="fa fa-fw fa-eye field-icon toggle-password"
												:class="eyeconfir" style="margin-right: 25px;"
												@click="cambioType('configpassword')"></span>
											<span toggle="#configpassword" class="fa fa-fw field-icon toggle-password"
												:class="passwordMatch ? 'fa-check' : 'fa-times'"
												:style="(passwordMatch) ? 'color: rgba(10, 81, 35, 1)' : 'color: red;'"></span>
										</div>
									</div>
									<div class="col-lg-12 col-md-12" style="margin-top: -25px;">
										<label class="error" v-if="errorLabelPass">LA conytraseña debe tener más de 8
											caracteres</label>
										<!-- Solo se permite letras mayuscula, minuscula, números y caracteres especiales -->
									</div>
								</div><br>
								<div class="form-group">
									<button type="submit" :disabled="readonly"
										class="mi-boton form-control btn mi-boton-green submit px-3">{{
										$t('start.CAccount') }}</button>
								</div>
							</form>

						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, maxLength, minLength, email } from '@vuelidate/validators';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';


export default {
	name: 'Register-Page',
	components: {
		Loading
	},
	props: {
		idioma: String
	},
	data() {
		return {
			readonly:true,
			classTipo: true,
			passwordMatch: true,
			type: 'password',
			previouscorreo: '',
			typeconfir: 'password',
			eye: '',
			errorLabelPass: false,
			maxlength:0,
			negocio: false,
			eyeconfir: '',
			disabled: true,
			isMobileNumberValid: false,
			isEmailValid: false,
			isNITValid: false,
			isSappellValid: false,
			isPappellValid: false,
			isNombreValid: false,
			isDireccionValid: false,
			isLoading: false,
			tipoForm: {},
			OptionsSelectProvincia: this.$global.provincia,
			OptionsSelectPais: [],
			OptionsSelectMunicipio: this.$global.municipio,
			form: {
				user: {
					configpassword: "",
					direccion: "",
					password: "",
					correo: "",
					sapell: "",
					papell: "",
					nombre: "",
					provincia: "",
					municipio: "",
					identificadorPais: "",
					telefonoMovil: "",
					NIT: "",
					pais: "",
					selectedOption: "persona",
				}
			}
		}
	},
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	validations() {
		return {
			form: this.tipoForm
		}
	}, 
	mounted() {
		// Intenta obtener los datos de sessionStorage inmediatamente
		this.checkAndSetPaises();

		// Si los datos aún no están disponibles, verifica periódicamente
		this.intervalId = setInterval(this.checkAndSetPaises, 1000); // Verifica cada 1 segundo
	},
	beforeUnmount() {
		// Limpiar el intervalo cuando el componente se destruya
		clearInterval(this.intervalId);
	},
	methods: {
		setTouched(theModel) {
			if (theModel == 'NIT' || theModel == 'all') { this.v$.form.user.NIT.$touch() }
		},
		cambioType(elment) {
			switch (elment) {
				case 'password':
					this.type = (this.type == 'password') ? 'text' : 'password'
					this.eye = (this.eye == '') ? 'fa-eye-slash' : ''
					break;
				case 'configpassword':
					this.typeconfir = (this.typeconfir == 'password') ? 'text' : 'password'
					this.eyeconfir = (this.eyeconfir == '') ? 'fa-eye-slash' : ''
					break;
			}
		},
		validateNIT() {
			const NIT = this.form.user.NIT;
			const regex = /^[0-9]{11}$/; // Expresión regular que coincide con exactamente 11 dígitos y solo numeros
			this.isNITValid = regex.test(NIT);
			return regex.test(NIT);

		},
		SelectionRadio() {
			//Calcular importe total
			if (this.form.user.selectedOption === "persona") {
				this.negocio = false;
				this.tipoForm = {
					user: {
						configpassword: { required },
						password: { required },
						correo: { required, email },
						sapell: { required },
						papell: { required },
						nombre: { required },
						pais: { required },
						identificadorPais: { required },
						selectedOption: { required },
					}
				}				
				this.form.user.pais = '';
				this.form.user.identificadorPais = '';
			}
			if (this.form.user.selectedOption === "negocio") {
				this.negocio = true;
				this.tipoForm = {
					user: {
						configpassword: { required },
						direccion: { required },
						password: { required },
						correo: { required, email },
						sapell: { required },
						papell: { required },
						nombre: { required },
						provincia: { required },
						municipio: { required },
						pais: { required },
						identificadorPais: { required },
						telefonoMovil: { required },
						selectedOption: { required },
						NIT: { required, minLength: minLength(11), maxLength: maxLength(11) }
					}
				}
				this.form.user.pais = 0;
				this.form.user.correo = '';
				this.searchPais()
			}
		},
		checkPasswordMatch() {
			const password = this.form.user.password;
			// const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=(?:\D*\d){3,})(?=.*[@$!%*?&-+()])[A-Za-z\d@$!%*?&-+()]+.{0,}$/

			// if (regex.test(password)) {
			// 	this.disabled = false;
			// 	this.errorLabelPass = false;
			// }else{
			// 	this.disabled = true;
			// 	this.errorLabelPass = true;
			// }
			if (this.form.user.password === '') {
				this.disabled = true;
				this.errorLabelPass = false;
			} else {
				this.disabled = false;
				this.errorLabelPass = false;
			}
			this.passwordMatch = password === this.form.user.configpassword;
		},
		getProvMun(event, status) {
			switch (status) {
				case 'provincia':
					this.form.user.provincia = event.target.value;
					this.OptionsSelectMunicipio = this.$global.municipio.filter(provincia => provincia.provincia_id.toString().startsWith(this.form.user.provincia))
					break;
				case 'municipio':
					this.form.user.municipio = event.target.value;
					break;
			}
		},
		searchPais() {
			const identMovil = this.OptionsSelectPais.find(option => option.id === this.form.user.pais);
			if (identMovil) {
				this.form.user.identificadorPais = identMovil.identificadorPais;
				this.maxlength = identMovil.countDigitos;
				this.readonlyActive = false;				
			}else{
				this.maxlength = 0
			}
			this.form.user.telefonoMovil = '';
		},
		handleKeydown(event) {
			// Evitar borrar el código generado automáticamente con el botón Backspace
			if (event.key === 'Backspace' && this.form.user.identificadorPais === this.OptionsSelectPais[this.form.user.pais - 1]) {
				event.preventDefault();
			}
		},
		async enviarDatos() {
			try {
				let errorSMS = false;
				let textSMS = '<p>';
				this.isLoading = true;
				this.datosCliente = true;
				if (this.v$.$invalid) {
					textSMS += '<h5> Datos Incorrectos, verifique la información insertada.</h5><br>';
					errorSMS = true;
				}
				if (!this.passwordMatch) {
					textSMS += '* La contraseña no es igual a la confirmada. Por favor vuelva a escribirla.<br>';
					errorSMS = true;
				}
				if (!this.isMobileNumberValid) {
					textSMS += '* El móvil insertado no es correcto.<br>';
					errorSMS = true;
				}
				if (this.negocio) {
					if (!this.isNITValid) {
						textSMS += '* El CI no es valido.<br>';
						errorSMS = true;
					}
					if (!this.isEmailValid) {
						textSMS += ' * El correo electronico no es valido.<br>';
						errorSMS = true;
					}
					if (this.form.user.provincia === '') {
						this.$swal.fire("Error!", "", "error");
						textSMS += '* Debe selecionar una provincia.<br>';
						errorSMS = true;
					}
					if (this.form.user.municipio === '') {
						textSMS += '* Debe selecionar un municipio.<br>';
						errorSMS = true;
					}
				}
				if (errorSMS) {
					textSMS += '</p>';
					this.$swal.fire({
						title: "Validación de Formulario",
						icon: "error",
						html: textSMS,
						showCloseButton: true,
						showCancelButton: true,
						showConfirmButton: false,
						cancelButtonText: "Cerrar",
					});
					this.isLoading = false;
					return;
				}
				this.$http.http.post('tienda/craeteCliente', this.form)
					.then((response) => {
						if (response.status === 200) {		
							if (response.data.registrada) {
								// this.$swal.fire(
								// 	this.$t('Create_Account.message.infoNewUser',{username: this.form.user.nombre+' '+this.form.user.papell }),
								// 	this.$t('Create_Account.message.exitUser', { email: this.form.user['correo'], username: response.data.username }),
								// 	"info"
								// );
							} else {
								// this.$swal.fire(
								// 	this.$t('Create_Account.message.welcome',{ username: this.form.user.nombre+' '+this.form.user.papell }),
								// 	this.$t('Create_Account.message.welcomeMessage', {  email: this.form.user['correo'], username: response.data.username }),
								// 	"success"
								// );
								this.$swal.fire({
									title: this.$t('Create_Account.message.welcome'),
									html: `<div style="text-align: justify;">
										${this.$t('Create_Account.message.mensajeFijo', { email: this.form.user.correo })}										
										${this.$t('Create_Account.message.mensajeFijo2')}
										${this.$t('Create_Account.message.mensajeFijo3', { contact: `+5350755710 / +5352528178`, email: `soporte@saldogrin.com`})}
										</div>`,
									icon: "success"
								});
								this.$router.push('/recarga');
							}
						}
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.$router.push('/recarga');
						this.isLoading = false;
					});
			} catch (error) {
				this.$swal.fire(
					this.$t('sms.error'),
					"No se pudo generar el token reCAPTCHA. Por favor intente nuevamente.",
					"error"
				);
				this.isLoading = false;
			}
		},		
		handleInputChange() {
			// Función que se ejecuta cuando el input cambia
			if (this.form.user.correo !== this.previouscorreo) {
				this.readonly = true;
				// Actualiza el valor previo para futuras comparaciones
				this.previouscorreo = this.form.user.correo;
			}
		},
		searchCorreo(){
			if (this.form.user.correo != '') {
				if (!this.validarEmail()) {
					this.$swal.fire("Error!", "El Correo Electronico no es Válido.", "error");
					return;
				}
			} else {
				return;
			}

			const datos = {
				'correo': this.form.user.correo
			}

			const queryString = new URLSearchParams(datos).toString();
			this.isLoading = true;
			this.$http.http.get(`tienda/getClienteRegister?${queryString}`)
				.then((response) => {
					if (response.status == 200) {
						if (response.data.isCliente === false) {
							this.readonly = false;
						} else {
							this.readonly = true;															
							this.$swal.fire({
									title: this.$t('Create_Account.message.infoNewUser'),
									html: `<div style="text-align: justify;">
										${this.$t('Create_Account.message.exitUser', { email: this.form.user.correo })}	
										<br><br>
										${this.$t('Create_Account.message.mensajeFijo3', { contact: `+5350755710 / +5352528178 <br>`, email: `soporte@saldogrin.com`})}
										</div>`,
									icon: "info"
								});
						}
						this.isLoading = false;
					}
				})
		},
		validateMobileNumber(length) {
			const phoneNumberRegex = new RegExp(`^\\d{${length}}$`);
			this.isMobileNumberValid = phoneNumberRegex.test(this.form.user.telefonoMovil);
		},
		validarEmail() {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			this.isEmailValid = emailRegex.test(this.form.user.correo);
			return this.isEmailValid;
		},
		validarText() {
			this.isSappellValid = (this.form.user.sapell) ? true : false;
			this.isPappellValid = (this.form.user.papell) ? true : false;
			this.isNombreValid = (this.form.user.nombre) ? true : false;
			this.isDireccionValid = (this.form.user.direccion) ? true : false;
		},
		checkAndSetPaises() {
			const paises = JSON.parse(sessionStorage.getItem('paises'));
			if (paises) {
				this.OptionsSelectPais = paises;
				clearInterval(this.intervalId); // Detiene el intervalo cuando los datos están disponibles
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.error {
	color: red;
	/* Color de texto en rojo */
	font-style: italic;
	/* Estilo de fuente en cursiva */
	font-size: 14px;
	/* Tamaño de fuente de 8px */
	padding-left: 5px;
	/* Agregar un pequeño espacio a la izquierda para que no esté pegado al borde del input */
}

.form-select {
	--bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
	display: block;
	width: 100%;
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--bs-body-color);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: var(--bs-body-bg);
	background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: var(--bs-border-radius);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type=radio] {
	accent-color: green;
}

.form-check-input:checked {
	background-color: green;
	border-color: green;
}
</style>