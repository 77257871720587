<template>
  <div>
    <div class="btn-group" role="group">
      <button
        id="btnGroupDrop1"
        type="button"
        class="btn btn-green-light mi-boton dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-bs-flip="false"
      >
        {{ $t('start.Download') }}
      </button>
      <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <a class="dropdown-item" href="#" @click="openModal">Descargar APK Cliente</a>
        <a class="dropdown-item" href="#">Descargar Video</a>
        <a class="dropdown-item" href="#">Descargar Manual de Uso</a>
      </div>
    </div>

    <!-- Modal para mostrar opciones de descarga -->
    <div
      class="modal fade"
      id="apkModal"
      tabindex="-1"
      aria-labelledby="apkModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="apkModalLabel">Descargas Disponibles</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            <!-- Imágenes con enlaces a las tiendas -->
            <p hidden>Elige la plataforma para descargar:</p>
            <div class="download-options" hidden>
              <a
                href="https://play.google.com/store/apps/details?id=com.tuapp.android"
                target="_blank"
                style="margin-top:-6px"
              >
                <img
                  src="@/assets/images/hmmQzZRrU87.png"
                  alt="Consíguelo en Google Play"
                  class="store-logo"
                  height="65px"
                />
              </a>
              <a
                href="https://apps.apple.com/app/idXXXXXXXXX"
                target="_blank"
              >
                <img
                  src="@/assets/images/2O6bJg90lJR.svg"
                  alt="App Store"
                  class="store-logo"
                />
              </a>
            </div>
            <p>Descarga directamente la APK:</p>
            <a href="./SaldoGrin_Cliente.apk" class="btn btn-green-light">
              Descargar APK <i class="fa fa-download"></i>
            </a>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: 'DropdownView',
  methods: {
    openModal() {
      const modal = new Modal(document.getElementById("apkModal"));
      modal.show();
    },
  },
};
</script>

<style scoped>
  .dropdown-menu {
    background-color: rgba(25, 135, 84, 0.9);
    color: #fff;
    border: none;
    width: 100%;
    padding: 10px 0;
    border-radius: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
  }

  .dropdown-item {
    color: #fff;
    padding: 5px 20px;
    transition: background-color 0.3s ease;
  }

  .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  #apkModal {
    --bs-modal-width: 500px !important;
  }

  .modal-content {
    background-color: rgba(25, 135, 84, 0.9);
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }

  .store-logo {
    width: 150px;
    margin: 10px;
  }

  .download-options {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
</style>
