<template>  

<div class="banner">
    <div class="container banner-texto">
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <h1 class="titulo">{{ $t('start.sistem') }}</h1>
          <h2 class="subtitulo">{{ $t('start.subtitulo') }}</h2>
          <p>{{ $t('start.subtitulo_p') }}</p>
          <div class="row ">
            <!-- <button class="btn btn-success mi-boton col-lg-5">Crear cuenta</button>             -->
            <router-link :to="'/'+locale+'/register'" class="btn btn-success mi-boton col-xl-5">{{ $t('start.CAccount') }}</router-link>
            <!-- <button type="button" class="btn mi-boton-green mi-boton col-lg-5">Recargar Saldo</button> -->
            <router-link :to="'/'+locale+'/recarga'" class="btn mi-boton-green mi-boton col-xl-5">{{ $t('start.RBalance') }}</router-link>
          </div>
        </div>
        <div class="col-lg-5 d-xl-none imagen">

        </div>
      </div>
    </div>
  </div>  

</template>
    
    <script>
    export default {
      name: 'Banner-View', 
      props: {
        language: String
      },
      data(){
        return {
          locale: this.language,
        }
      },
    }
    </script>
    
    <style>
    
    </style>
    