<template>
	<loading v-model:active="isLoading" :can-cancel="false" :is-full-page=true color="rgba(10, 81, 35, 1)" loader="dots"
		:width=100 :height=100 :opacity=0.5 :zIndex=999 backgroundColor="#6c757d" />

	<section class="ftco-section">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-7 col-lg-5">
					<div class="wrap">
						<div class="login-wrap p-4 p-md-5">
							<div class="d-flex">
								<div class="w-100">
									<h2>{{ $t('start.RBalance') }}</h2>
								</div>

							</div>
							<form @submit.prevent="recargarTropipay" class="signin-form">
								<div class="form-group mt-3">
									<input type="email" class="form-control" autocomplete="off" id="correo" required
										v-model="form.correo" @input="handleInputChange" @blur="searchCliente()">
									<label class="form-control-placeholder" for="correo">{{ $t('Create_Account.email')
										}}</label>
									<span toggle="#correo" class="fa fa-fw fa-search field-icon toggle-password"
										style="color:rgba(10, 81, 35, 1)" @click="searchCliente()"></span>
								</div>
								<div class="form-group">
									<input id="montoRecargar" type="text" autocomplete="off" class="form-control"
										required :disabled="disabled" v-model="form.montoRecargar"
										@input="formatoNumero">
									<label class="form-control-placeholder" for="montoRecargar">{{
										$t('Recharge_Balance.amount') }}</label>
								</div>
								<div class="form-group">
									<h3>{{ $t('Recharge_Balance.Payment') }}:</h3>
									<div
										class="row  justify-content-center flex-row flex-md-nowrap flex-sm-nowrap text-center mb-3">
										<div class="col-lg-12 col-md-12 col-sm-12">
											<div class="form-check form-check-inline col-lg-5 col-md-3 col-sm-1">
												<input type="radio" id="opcion2" value="tropipay"
													class="form-check-input" v-model="selectedOption"
													:disabled="disabled" @change="SelectionRadio" />
												<img class="" src="../assets/images/logo-tropipay.png" width="100"
													height="30" for="opcion2" alt="Tropipay" loading="lazy">
											</div>
											<div class="form-check form-check-inline col-lg-5 col-md-3 col-sm-1">
												<input type="radio" id="opcion1" value="visa" v-model="selectedOption"
													class="form-check-input" @change="SelectionRadio"
													:disabled="disabled" />
												<img class="" src="../assets/images/visa.svg" width="100" height="30"
													for="opcion1" alt="Visa" loading="lazy">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<h3><strong>{{ $t('Recharge_Balance.Amount_Recharge') }}:</strong> {{ importeTotal
										}}</h3>
								</div>
								<div class="form-group">
									<button type="submit" class="mi-boton form-control btn mi-boton-green submit px-3"
										:disabled="disabled">{{ $t('Recharge_Balance.Recharge') }}</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-md-7 col-lg-5" v-if="datosCliente">
					<div class="wrap">
						<div class="login-wrap p-4 p-md-5">
							<div class="d-flex">
								<div class="w-100">
									<h2>{{ $t('Recharge_Balance.Client_Data') }}</h2>
								</div>
							</div>
							<div class="form-group">
								<p class="text-left"><strong>{{ $t('Recharge_Balance.name') }}:</strong> {{
									cliente.nombre }}</p>
							</div>

							<div class="form-group">
								<p class="text-left"><strong>{{ $t('Recharge_Balance.mail') }}:</strong> {{
									cliente.correo }}</p>
							</div>

							<div class="form-group">
								<p class="text-left"><strong>{{ $t('Recharge_Balance.Available') }}:</strong> $G {{
									parseFloat(cliente.saldoGrin).toFixed(2) }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
	name: 'Recarga-Page',
	components: {
		Loading
	},
	props: {
		msg: String,
		idioma: String
	},
	data() {
		const importeTotal = parseFloat(0).toFixed(2);
		return {
			locale: this.idioma,
			selectedOption: "tropipay",
			importeTotal,
			disabled: true,
			isLoading: false,
			datosCliente: false,
			cliente: {
				nombre: '',
				correo: '',
				saldoGrin: ''
			},
			form: {
				montoRecargar: "",
				correo: ""
			},
			previouscorreo: ''
		}
	},
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	validations() {
		return {
			form: {
				correo: { required, email },
				montoRecargar: { required }
			}
		}
	},
	methods: {
		setTouched(theModel) {
			if (theModel == 'correo' || theModel == 'all') { this.v$.form.correo.$touch() }
			if (theModel == 'montoRecargar' || theModel == 'all') { this.v$.form.montoRecargar.$touch() }
		},
		searchCliente() {
			if (this.form.correo != '') {
				if (!this.validatecorreo()) {
					this.$swal.fire("Error!", "El Correo Electronico no es Válido.", "error");
					// this.$popIt.error('Error!', 'El Correo Electronico no es Válido.')
					this.form.correo = ''
					return;
				}
			} else {
				return;
			}

			const datos = {
				'correo': this.form.correo
			}

			const queryString = new URLSearchParams(datos).toString();
			this.isLoading = true;
			this.$http.http.get(`tienda/getClienteEmail?${queryString}`)
				.then((response) => {
					if (response.status == 200) {
						if (response.data.length !== 0) {
							this.cliente = response.data
							this.datosCliente = true;
							this.disabled = false;
						} else {
							this.datosCliente = false;
							this.disabled = true;
							this.$swal.fire(
								this.$t('sms.info'),
								this.$t('Create_Account.message.searchClient'),
								"warning"
							);
						}
						this.isLoading = false;
					}
				})
		},
		validatecorreo() {
			const correo = this.form.correo;
			const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return regex.test(correo);
		},
		SelectionRadio() {
			//Calcular importe total
			if (this.selectedOption === "tropipay") {
				const calculoTropipay = ((parseFloat(this.form.montoRecargar) * 1) / 100);
				this.importeTotal = (parseFloat(this.form.montoRecargar) - parseFloat(calculoTropipay)).toFixed(2);
			}
			if (this.selectedOption === "visa") {
				const calculoTropipay = ((parseFloat(this.form.montoRecargar) * 3.45) / 100) + parseFloat(0.50);
				const calculoGrin = ((parseFloat(this.form.montoRecargar) * 1.55) / 100) + parseFloat(0.30);
				this.importeTotal = (parseFloat(this.form.montoRecargar) - (parseFloat(calculoTropipay) + parseFloat(calculoGrin))).toFixed(2);
			}
			if (isNaN(this.importeTotal)) {
				this.importeTotal = parseFloat(0).toFixed(2);
			}
		},
		handleInputChange() {
			// Función que se ejecuta cuando el input cambia
			if (this.form.correo !== this.previouscorreo) {
				this.datosCliente = false;
				this.disabled = true;
				// this.searchCliente();
				// Actualiza el valor previo para futuras comparaciones
				this.previouscorreo = this.form.correo;
				this.form.montoRecargar = '';
				this.importeTotal = parseFloat(0).toFixed(2);
			}
		},
		formatoNumero() {
			// Elimina todos los caracteres que no sean números o coma
			this.form.montoRecargar = this.form.montoRecargar.replace(/[^0-9.]/g, '');

			// Si hay más de una coma, elimina las extras
			const parts = this.form.montoRecargar.split('.');
			if (parts.length > 2) {
				this.form.montoRecargar = parts[0] + '.' + parts.slice(1).join('');
			}
			this.SelectionRadio();
		},
		async recargarTropipay() {
			try {
				this.isLoading = true;
				this.datosCliente = true;
				if (this.v$.$invalid) {
					this.$swal.fire(
						this.$t('sms.error'),
						this.$t('Create_Account.message.validarRecarga'),
						"error"
					);
					this.isLoading = false;
					return;
				}
				const datos = new FormData();
				const tipoCard = (this.selectedOption === "tropipay") ? true : false;
				datos.append('email', this.form.correo);
				datos.append('monto', this.form.montoRecargar);
				datos.append('importe_recargar', this.importeTotal);
				datos.append('tipoCard', tipoCard);
				datos.append('idioma', this.locale);
				// datos.append('recaptchaResponse', this.recaptchaResponse);
				this.$http.http.post('tienda/CardPayment', datos)
					.then((response) => {
						if (response.data.code == 200) {
							this.datosCliente = false;
							this.disabled = true;
							// this.searchCliente();
							// Actualiza el valor previo para futuras comparaciones
							this.previouscorreo = this.form.correo;
							this.form.montoRecargar = '';
							this.importeTotal = parseFloat(0).toFixed(2);
							this.selectedOption = "tropipay"
							this.abrirVentana(response.data.url);
							this.isLoading = false;
						} else {
							this.$swal.fire("Error!", "Error al crear una conexion con tropipay", "error");
						}
					})
					.catch((error) => {
						// this.$popIt.error(
						// 	'Error!', error.response.data.mensaje
						// )

						this.$swal.fire("Error!", error.response.data.mensaje, "error");
						this.isLoading = false;
					})

			} catch (error) {
				this.$swal.fire("Error!", "No se pudo generar el token reCAPTCHA. Por favor intente nuevamente.", "error");
				this.isLoading = false;
			}
		},
		abrirVentana(popupUrl) {
			if (popupUrl) {
				window.open(popupUrl);
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
input[type=radio] {
	accent-color: green;
}

.form-check-input:checked {
	background-color: green;
	border-color: green;
}
</style>